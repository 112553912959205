import firebase from '../index';

const points = firebase.firestore().collection('points');

const getPoints = async () => {
  const snapshot = await points.doc('point').get();
  return snapshot.data();
};

const getExtraPoint = async () => {
  const snapshot = await points.doc('extraPoint').get();
  return snapshot.data();
};

export default {
  getPoints,
  getExtraPoint,
};

import moment from 'moment';
import pointService from '../firebase/firestore/points';
import paymentService from '../firebase/firestore/payments';
import fieldValue from '../firebase/firestore/fieldValue';
import firebase from '../firebase/index';
import api from '../firebase/function/index';
import store from '../store';

const fbDate = firebase.firestore.Timestamp;
const users = firebase.firestore().collection('users');


const convertExpDate = (startDate, pointMaxAge) => {
  const expDate = moment(startDate.toDate()).add(pointMaxAge, 'd').format('YYYY-MM-DDTHH:mm');
  const curDate = moment().format('YYYY-MM-DDTHH:mm');
  return moment(expDate).isAfter(curDate) ? fbDate.fromDate(new Date(expDate)) : null;
};

const tranRecordsToHis = async (records) => {
  const { pointMaxAge, ratio } = await pointService.getPoints();
  const his = await records.map((record) => {
    const data = {
      exp: convertExpDate(record.purchaseDate, pointMaxAge),
      points: Math.floor(record.totalPrice / ratio),
      type: 'convert',
      action: 'fill',
      status: false,
    };
    return data;
  });
  return his;
};

const getCumaData = async (cumaToken, date, pointMaxAge) => {
  if (!cumaToken) {
    return { sumCumaPrice: 0, cumaHis: [] };
  }
  try {
    const { sumPrice, record } = await api.getCumaPurchaseRecords({
      date,
      pointMaxAge,
      token: cumaToken,
    });
    const tranHis = await tranRecordsToHis(record);
    return {
      sumCumaPrice: sumPrice || 0,
      cumaHis: tranHis && tranHis.length ? tranHis : [],
    };
  } catch (err) {
    return { sumCumaPrice: 0, cumaHis: [] };
  }
};

const calRank = (currPoint, listRank) => {
  const checkRank = listRank.filter(r => r.criteria <= currPoint);
  return checkRank.length ? checkRank[checkRank.length - 1].rank : '';
};

const manageHis = async (expRecords) => {
  const { uid } = store.getters.user;
  await store.dispatch('getProfile', { uid });
  const {
    convertedPoints,
    currPoints,
    extraPoints,
    purchasedPoints,
    rankPoints,
    expHis,
    pointHis,
  } = store.getters.profile;
  let converted = 0;
  let extra = 0;
  let purchased = 0;
  expRecords.forEach((record) => {
    if (record.type === 'convert' && !record.status) converted += record.points;
    else if (record.type === 'extra' && !record.status) extra += record.points;
    else if (record.type === 'purchased' && !record.status) purchased += record.points;
  });
  const data = {
    convertedPoints: convertedPoints - converted < 0
      ? 0 : convertedPoints - converted,
    currPoints: currPoints - (converted + extra + purchased) < 0
      ? 0 : currPoints - (converted + extra + purchased),
    rankPoints: rankPoints - (converted + extra + purchased) < 0
      ? 0 : currPoints - (converted + extra + purchased),
    extraPoints: extraPoints - extra < 0
      ? 0 : extraPoints - extra,
    purchasedPoints: purchasedPoints - purchased < 0
      ? 0 : purchasedPoints - purchased,
    lastUpdate: fieldValue.serverTimestamp(),
  };
  if (expRecords.length) {
    data.expHis = [...expHis, ...expRecords];
    const arrFilterNotExp = pointHis
      .filter(hi => !expRecords
        .filter(exp => JSON.stringify(exp) === JSON.stringify(hi)).length);
    data.pointHis = arrFilterNotExp.filter(arr => arr !== null);
  }
  const { pointRanks } = await pointService.getPoints();
  const sumPointsToCheckRank = data.purchasedPoints + data.convertedPoints + data.extraPoints;
  data.memberLevel = calRank(sumPointsToCheckRank, pointRanks);
  if (data.memberLevel === '') {
    data.isMember = false;
  }
  store.dispatch('setProfile', { uid, data })
    .then(() => {
      store.dispatch('getProfile', { uid });
    });
  return data.memberLevel;
};


const applyMember = async () => {
  const { pointMaxAge, pointRanks, ratio } = await pointService.getPoints();
  const { uid } = store.getters.user;
  await store.dispatch('getProfile', { uid });
  const { cumaToken } = store.getters.profile;
  const trans = await paymentService.getUserTransactions({ uid });
  const filterTrans = trans.filter(tran => tran.invoiceId !== undefined);
  const createHis = await Promise.all(filterTrans.map(async (tran) => {
    const odId = tran.orderId;
    const order = await paymentService.getSingleOrder({ odId });
    const { price, discount, added } = order;
    const expDate = convertExpDate(added, pointMaxAge);
    const total = price - discount;
    const points = total < 0 ? 0 : total;
    return expDate ? {
      exp: expDate,
      points,
      type: 'convert',
      action: 'fill',
      status: false,
    } : {};
  }));
  const cuma = await getCumaData(cumaToken, moment(), pointMaxAge);
  const { sumCumaPrice, cumaHis } = cuma;

  const amountPrice = createHis.reduce((sum, pointHi) => sum + pointHi.points, 0) + sumCumaPrice;
  const convertedPoints = Math.floor(amountPrice / ratio) || 0;
  const extraPoints = await pointService.getExtraPoint();

  const extraHis = extraPoints ? [{
    exp: fbDate.fromDate(new Date(extraPoints.exp)),
    points: extraPoints.points,
    type: 'extra',
    action: 'fill',
    status: false,
  }] : [];

  const pointStructure = {
    convertedPoints,
    currPoints: convertedPoints + extraPoints.points,
    rankPoints: convertedPoints + extraPoints.points,
    expHis: [],
    extraPoints: extraPoints.points,
    isMember: true,
    lastUpdate: fieldValue.serverTimestamp(),
    pointHis: [...createHis, ...cumaHis, ...extraHis],
    purchasedPoints: 0,
    memberLevel: calRank(convertedPoints + extraPoints.points, pointRanks),
    memberStartDate: fieldValue.serverTimestamp(),
  };
  store.dispatch('setProfile', { uid, data: pointStructure })
    .then(() => {
      store.dispatch('getProfile', { uid });
    });
};

const checkStatusIsMem = async () => {
  const { uid } = store.getters.user;
  await store.dispatch('getProfile', { uid });
  const { isMember, pointHis, memberLevel } = store.getters.profile;
  if (!isMember) {
    return null;
  }
  const expPoint = pointHis
    ? pointHis.filter(hi => moment(typeof hi.exp === 'string'
      ? new Date(hi.exp) : hi.exp.toDate()).isBefore(moment()))
    : [];
  const res = await manageHis(expPoint);
  return expPoint.length ? res : memberLevel;
};

const checkDuplicateEmail = async (cumaEmail) => {
  const snapshot = await users.where('cumaEmail', '==', cumaEmail).get();
  return snapshot.size;
};

const getPointConfig = () => Promise.all([pointService.getPoints()]);

const linkCumaData = async () => {
  const { user } = store.getters;
  const { uid } = user;
  await store.dispatch('getProfile', { uid });
  const { profile } = store.getters;
  const { cumaToken, pointHis, rankPoints } = profile;
  const { pointMaxAge, pointRanks, ratio } = await pointService.getPoints();
  const cuma = await getCumaData(cumaToken, moment(), pointMaxAge);
  const { sumCumaPrice, cumaHis } = cuma;
  const convertedPoints = Math.floor(sumCumaPrice / ratio) || 0;
  const data = {
    convertedPoints: fieldValue.increment(convertedPoints),
    currPoints: fieldValue.increment(convertedPoints),
    rankPoints: fieldValue.increment(convertedPoints),
    pointHis: [...pointHis, ...cumaHis],
    memberLevel: calRank(rankPoints + convertedPoints, pointRanks),
    lastUpdate: fieldValue.serverTimestamp(),
  };
  await store.dispatch('setProfile', { uid, data });
};

export default {
  applyMember,
  checkStatusIsMem,
  checkDuplicateEmail,
  getPointConfig,
  linkCumaData,
};

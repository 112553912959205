<template>
  <v-app>
    <v-container>
      <v-container class="white-bg mt-48px">
        <v-row justify="center">
          <h4 class="navy-text">PAYMENT</h4>
        </v-row>
        <v-row justify="center">
          <div class="subtitle">การชำระเงิน</div>
        </v-row>
        <v-divider></v-divider>
        <v-content>
          <!-- Section 1 -->
          <div class="ct-mw">
            <v-row>
              <v-col cols="6">
                <v-row justify="start" class="ma-0">
                  <p><strong>สรุปรายการสั่งซื้อ</strong></p>
                </v-row>
              </v-col>
              <v-col cols="6">
                <v-row justify="end">
                  <!-- <p>Order ID : {{ orderId }} </p> -->
                </v-row>
              </v-col>
            </v-row>
            <div class="section">
              <v-row >
                <v-col md="9" cols="8">
                  <v-row tag="caption" justify="start" class="ml-2">
                    <p><strong>Product Name</strong></p>
                  </v-row>
                  <v-row tag="caption" justify="start" class="ml-2">
                    <span class="detail-text">{{ productName }}</span>
                  </v-row>
                  <v-row tag="caption" justify="start" class="ma-2">
                    <cumaPopup v-if="$store.getters.profile
                      && !$store.getters.profile.cumaEmail"></cumaPopup>
                  </v-row>
                </v-col>
                <v-col md="3" cols="4">
                  <v-row tag="caption" justify="end" class="mr-2">
                    <p><strong>Price</strong></p>
                  </v-row>
                  <v-row tag="caption" justify="end" class="mr-2">
                    <span class="detail-text">{{ getPrice() }} บาท</span>
                  </v-row>
                </v-col>
              </v-row>
              <!-- Check Member Promotion -->
              <div v-if="price > 0">
                <div v-if="isEarly && !noPromo">
                  <v-radio-group v-model="radiosEarly" :mandatory="false">
                    <v-radio
                      tag="caption"
                      class="caption"
                      label="ส่วนลด Early Bird"
                      value="earlySelected">
                    </v-radio>
                    <v-radio
                      label="ใส่ Promotion Code"
                      value="promotionSelected">
                    </v-radio>
                  </v-radio-group>
                  <div v-if="radiosEarly=='promotionSelected'">
                    <v-row>
                      <v-col md="auto" cols="7">
                        <v-text-field
                          v-model="promotion"
                          label="Promotion Code"
                          mask="NNNNNNN"
                          :hint="promoHint"
                          solo
                        ></v-text-field>
                      </v-col>
                      <v-col md="auto" cols="5">
                        <v-btn @click="apply"
                        color="error" class="ma-2"><strong>APPLY</strong></v-btn>
                      </v-col>
                      <!-- <v-col md="2"></v-col> -->
                    </v-row>
                  </div>
                </div>
                <div v-else-if="isMember && !noPromo">
                  <v-radio-group v-model="radiosMember" :mandatory="false">
                    <v-radio
                      class="caption"
                      label="ส่วนลด Achieve Member"
                      value="memberSelected">
                    </v-radio>
                    <v-radio
                      label="ใส่ Promotion Code"
                      value="promotionSelected">
                    </v-radio>
                  </v-radio-group>
                  <div v-if="radiosMember=='promotionSelected'">
                    <v-row>
                      <v-col md="auto" cols="7">
                        <v-text-field
                          v-model="promotion"
                          label="Promotion Code"
                          mask="NNNNNNN"
                          :hint="promoHint"
                          solo
                        ></v-text-field>
                      </v-col>
                      <v-col md="auto" cols="5">
                        <v-btn @click="apply"
                        color="error" class="ma-2"><strong>APPLY</strong></v-btn>
                      </v-col>
                      <!-- <v-col md="auto" cols="2"></v-col> -->
                    </v-row>
                  </div>
                </div>
                <div v-else-if="!noPromo">
                  <v-row>
                    <v-col sm="auto" cols="7">
                      <v-text-field
                        v-model="promotion"
                        label="Promotion Code"
                        mask="NNNNNNN"
                        :hint="promoHint"
                        solo
                      ></v-text-field>
                    </v-col>
                    <v-col md="auto" cols="5">
                      <v-btn @click="apply"
                      color="error" class="ma-2"><strong>APPLY</strong></v-btn>
                    </v-col>
                    <!-- <v-col md="2"></v-col> -->
                  </v-row>
                </div>
                <!-- End of Check Member Promotion  -->
                <div v-if="!noPromo">
                  <v-row tag="caption" justify="end" class="ma-0">
                    <strong class="mr-1">Discount</strong>{{ showDiscount() }} บาท
                  </v-row>
                </div>
              </div>
              <v-row class="bg-grey ma-0">
                <v-col cols="6" tag="caption" class="ma-0 pa-0">
                  <v-row justify="start" class="ml-2">
                    <strong>Total</strong>
                  </v-row>
                </v-col>
                <v-col cols="6" tag="caption" class="ma-0 pa-0">
                  <v-row justify="end" class="mr-2">
                    <strong>{{ total() }} บาท</strong>
                  </v-row>
                </v-col>
              </v-row>
            </div>
            <!-- Section 2 -->
             <div v-if="price > 0">
              <v-row>
                <v-col cols="12">
                  <v-row justify="start" class="ma-0">
                    <p><strong>ท่านต้องการใบกำกับภาษีหรือไม่</strong></p>
                  </v-row>
                </v-col>
              </v-row>
              <div class="section">
                <v-radio-group
                  v-model="radioTax"
                  :mandatory="false"

                >
                  <v-radio
                    class="ra"
                    label="ไม่ต้องการ (ท่านจะได้รับใบกำกับภาษีอย่างย่อ
                    และยอมรับไม่สามารถเปลี่ยนเป็นใบกำกับภาษีเต็มรูปได้ทุกกรณี
                    สามารถดาวน์โหลดได้ที่ Profile > Payment)"
                    value="notNeed"></v-radio>
                  <v-radio
                    class="ra"
                    label="ต้องการ (ใบกำกับภาษีจะถูกจัดส่งไปตามชื่อที่อยู่ที่ท่านกรอกข้อมูล)"
                    value="need"></v-radio>
                </v-radio-group>
              </div>
              <!-- Section 3 : Tax invoice -->
              <div v-if="radioTax=='need'">
                <v-row >
                  <v-col cols="12">
                    <v-row justify="start" class="ma-2">
                      <p><strong>ข้อมูลการออกใบกำกับภาษี</strong></p>
                    </v-row>
                  </v-col>
                </v-row>
                <div class="section">
                  <v-row justify="start" tag="caption" class="ml-1">
                    <p>ชื่อ-ที่อยู่ในการออกใบกำกับภาษี</p>
                  </v-row>
                  <v-row class="ma-0 pa-0">
                    <v-col cols="12" sm="12" md="12" class="ma-0">
                      <v-text-field
                        v-model="nameth"
                        label="ชื่อ/นามบริษัท"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="ma-0 pa-0">
                    <v-col cols="12" sm="6" md="6" class="ma-0">
                      <v-text-field
                        v-model="taxid"
                        label="เลขประจำตัวผู้เสียภาษี / เลขบัตรประชาชน"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" class="ma-0">
                      <v-text-field
                        v-model="tel"
                        label="เบอร์โทรศัพท์"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="ma-0 pa-0">
                    <v-col cols="12" sm="12" md="12" class="ma-0">
                      <v-text-field
                        v-model="address"
                        label="ที่อยู่ (เลขที่ห้อง บ้านเลขที่ อาคาร หมู่บ้าน หมู่ที่ ซอย ถนน)"
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" class="ma-0">
                      <thai-address-input
                        class="v-text-field border-bottom"
                        type="search"
                        v-model="searchAddressTax"
                        @selected="onSelectedTax"
                        input-class="v-input"
                        placeholder="ค้นหาที่อยู่อัตโนมัติ"
                      ></thai-address-input>
                    </v-col>
                  </v-row>
                  <br>
                  <v-row class="ma-0 pa-0">
                    <v-col cols="12" sm="6" md="6" class="ma-0">
                      <v-text-field
                        label="ตำบล / แขวง"
                        v-model="subdistrict"
                        required
                        :disabled="radioTax === 'need'"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="6" md="6" class="ma-0">
                      <v-text-field
                        label="อำเภอ / เขต"
                        v-model="district"
                        required
                        :disabled="radioTax === 'need'"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="6" md="6" class="ma-0">
                      <v-text-field
                        type="province"
                        label="จังหวัด"
                        v-model="province"
                        required
                        :disabled="radioTax === 'need'"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="6" md="6" class="ma-0">
                      <v-text-field
                        label="รหัสไปรษณีย์"
                        v-model="postalCode"
                        :disabled="radioTax === 'need'"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </div>
                <!-- Post tax -->
                <div>
                  <v-row>
                    <v-col cols="12">
                      <v-row justify="start" class="ma-2"><br/>
                        <p><strong>ข้อมูลในการจัดส่งใบกำกับภาษี</strong></p>
                      </v-row>
                    </v-col>
                  </v-row>
                  <div class="section">
                    <v-row>
                      <v-col tag="caption">
                        <v-row justify="start" class="ml-1">
                          <p>ชื่อ-ที่อยู่ในการจัดส่ง</p>
                        </v-row>
                        <v-row justify="start" class="ma-0 pa-0">
                           <v-radio-group v-model="radioPost" :mandatory="false">
                            <v-radio
                              label="เหมือนกับชื่อที่อยู่ในการออกใบกำกับภาษี"
                              value="same"></v-radio>
                            <v-radio
                              label="ใช้ที่อยู่ใหม่"
                              value="notSame"></v-radio>
                          </v-radio-group>
                        </v-row>
                      </v-col>
                    </v-row>

                    <div v-if="radioPost=='notSame'">
                      <v-row class="ma-0 pa-0">
                        <v-col cols="12" sm="6" md="6" class="ma-0">
                          <v-text-field
                            v-model="namethPost"
                            label="ชื่อ/นามบริษัท"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="6" class="ma-0">
                          <v-text-field
                            v-model="telPost"
                            label="เบอร์โทรศัพท์"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row class="ma-0 pa-0">
                        <v-col cols="12" sm="12" md="12" class="ma-0">
                          <v-text-field
                            v-model="addressPost"
                            label="ที่อยู่ (เลขที่ห้อง บ้านเลขที่ อาคาร หมู่บ้าน หมู่ที่ ซอย ถนน)"
                            outlined
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="12" class="ma-0">
                          <thai-address-input
                            class="v-text-field border-bottom"
                            type="search"
                            v-model="searchAddressPost"
                            @selected="onSelectedPost"
                            input-class="v-input"
                            placeholder="ค้นหาที่อยู่อัตโนมัติ"
                          ></thai-address-input>
                        </v-col>
                      </v-row>
                      <v-row class="ma-0 pa-0">
                        <v-col cols="12" sm="6" md="6" class="ma-0">
                          <v-text-field
                            label="ตำบล / แขวง"
                            v-model="subdistrictPost"
                            required
                            disabled
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="6" md="6">
                          <v-text-field
                            label="อำเภอ / เขต"
                            v-model="districtPost"
                            required
                            disabled
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="6" md="6">
                          <v-text-field
                            type="province"
                            label="จังหวัด"
                            v-model="provincePost"
                            required
                            disabled
                          ></v-text-field>
                        </v-col>

                        <v-col xs="12" sm="6" md="6">
                          <v-text-field
                            label="รหัสไปรษณีย์"
                            v-model="postalCodePost"
                            disabled
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </div>
                    <div v-else></div>
                  </div>
                </div>
              </div>
              <div v-else></div>
              <!-- Checkbox accept terms of service -->
              <div>
                <v-checkbox
                  v-if="!submit"
                  v-model="checkbox"
                  :error-messages="checkboxErrors"
                  label="ข้าพเจ้ายอมรับ <router-link to='/termofservice' target='_blank'>
                      ข้อกำหนดในการให้บริการ</router-link>
                  และได้ตรวจสอบข้อมูลอย่างถูกต้องทุกประการแล้ว"
                  required
                >
                <template v-slot:label>
                  <div>
                    ข้าพเจ้ายอมรับ
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <a
                          target="_blank"
                          href="/termofservice"
                          @click.stop
                          v-on="on"
                        >
                          ข้อกำหนดในการให้บริการ
                        </a>
                      </template>
                      Opens in new tab
                    </v-tooltip>
                    และได้ตรวจสอบข้อมูลอย่างถูกต้องทุกประการแล้ว
                  </div>
                </template>
                </v-checkbox>
                <v-row justify="start" class="detail-text">
                  <p class="mx-3">
                    <u>หมายเหตุ</u> หากต้องการออกใบกำกับภาษีเต็มรูปในนามบริษัท
                    หรือจะขอคืนเงินภาษีหัก ณ ที่จ่าย
                    (เงื่อนไขและค่าธรรมเนียมการโอนเงินคืน เป็นไปตามที่ระบุใน
                    <router-link to="/termofservice" target="_blank">
                      ข้อกำหนดในการให้บริการ</router-link>
                    ซึ่งอยู่ในความรับผิดชอบของลูกค้า)
                    โปรดติดต่อ <a href="mailto:billing@achieve.plus" target="_blank">
                      billing@achieve.plus</a>
                    เพื่อแจ้งความจำนงพร้อมแนบเอกสาร (หนังสือรับรองบริษัท / ภพ.20 /
                    หนังสือรับรองการหักภาษี ณ ที่จ่าย / สำเนาหน้าสมุดบัญชีธนาคาร /
                    ใบยืนยันการชำระเงิน) มาทางอีเมลภายในวันที่ซื้อ
                    และจัดส่งเอกสารภายใน 3 วันทำการเท่านั้น นับจากวันที่ชำระเงิน
                  </p>
                </v-row>
                <v-row justify="center">
                  <v-btn :disabled="!checkNext"
                  class="mb-4"
                  v-on:click="next()" color="error">NEXT</v-btn>
                </v-row>
                <v-row justify="center" class="red-text">
                  *สินค้าที่เกี่ยวข้องกับ pathway หรือ course กรุณาทำการเชื่อมต่อกับ
                  CHULAMOOC ก่อน ถึงทำการซื้อได้ครับ
                </v-row>
              </div>
            </div>
          </div>
        </v-content>
      </v-container>
    </v-container>
  </v-app>
</template>

<script>
import numeral from 'numeral';
import payment from '../../library/payment';
import payments from '../../firebase/firestore/payments';
import products from '../../library/products';
import userServices from '../../firebase/firestore/users';
import cumaPopup from '../../components/LoginCUMA.vue';

const { errorMsg, getErrorMsg } = require('../../static/errorMessage');

export default {
  components: {
    cumaPopup,
  },
  data: () => ({
    orderId: 'ODP1905000010',
    productName: '',
    price: '',
    promotion: '',
    promoHint: 'Enter Promotion Code',
    radio1: false,
    radio2: false,
    submit: false,
    checkbox: false,
    checkboxPost: false,
    radioPost: 'same',
    radioTax: 'notNeed',
    radiosMember: 'memberSelected',
    radiosEarly: 'earlySelected',
    selectRadio: false,
    hidden: false,
    discount: 0,
    isEditing: false,
    nameth: '',
    taxid: '',
    tel: '',
    address: '',
    subdistrict: '',
    district: '',
    province: '',
    postalCode: '',
    namethPost: '',
    telPost: '',
    addressPost: '',
    subdistrictPost: '',
    districtPost: '',
    provincePost: '',
    postalCodePost: '',
    searchAddressTax: '',
    searchAddressPost: '',
    required: [],
    isMember: false,
    checkboxErrors: '',
    msg: '',
    memberStat: '',
    isEarly: false,
    productData: '',
    memberLevel: '',
    noPromo: false,
    needLink: false,
    cumaEmail: '',
  }),
  async mounted() {
    this.$store.dispatch('setLoading', true);
    const { uid } = this.$store.getters.user;
    // console.log(this.$store.getters.profile);
    // const { cumaEmail } = this.$store.getters.profile;
    // this.cumaEmail = cumaEmail;
    await this.$store.dispatch('getProfile', { uid });
    const { sku } = this.$route.params;
    const {
      pathwayList, workshopList, visitList, skuList, courseList,
    } = this.$store.getters.profile;
    if (!sku) {
      this.$store.dispatch('setError', errorMsg['other/fallback']);
      this.$router.push('/store');
    }
    if (!skuList) {
      const data = { skuList: [] };
      this.$store.dispatch('setProfile', { uid, data });
    } else if (skuList && skuList.includes(sku)) {
      this.$store.dispatch('setError', errorMsg['store/purchased']);
      this.$router.push('/store');
    }
    products.getProduct(sku)
      .then(async (productData) => {
        if (productData) {
          const {
            name, prices, earlyStart, earlyEnd, saleEnd, saleStart, participants, limit, items,
          } = productData;
          const { profile } = this.$store.getters;
          if (items.length > 1) this.noPromo = true;
          await Promise.all(items.map((item) => {
            if (item.type === products.PRODUCT_TYPE.WORKSHOP) {
              if (!workshopList) {
                const data = { workshopList: [] };
                this.$store.dispatch('setProfile', { uid, data });
              } else if (workshopList && workshopList.includes(item.id)) {
                this.$store.dispatch('setError', errorMsg['store/bundle-purchased-other']);
                this.$router.push('/store');
              }
            }
            if (item.type === products.PRODUCT_TYPE.COMPANY_VISIT) {
              if (!visitList) {
                const data = { visitList: [] };
                this.$store.dispatch('setProfile', { uid, data });
              } else if (visitList && visitList.includes(item.id)) {
                this.$store.dispatch('setError', errorMsg['store/bundle-purchased-other']);
                this.$router.push('/store');
              }
            }
            if (item.type === products.PRODUCT_TYPE.PATHWAY) {
              this.needLink = true;
              if (!pathwayList) {
                const data = { pathwayList: [] };
                this.$store.dispatch('setProfile', { uid, data });
              } else if (pathwayList && pathwayList.includes(item.id)) {
                this.$store.dispatch('setError', errorMsg['store/bundle-purchased-pathway']);
                this.$router.push('/store');
              }
            }
            if (item.type === products.PRODUCT_TYPE.COURSE) {
              if (!courseList) {
                const data = { courseList: [] };
                this.$store.dispatch('setProfile', { uid, data });
              } else if (courseList && courseList.includes(item.id)) {
                this.$store.dispatch('setError', errorMsg['store/course']);
                this.$router.push('/store');
              }
            }
            return item;
          }));
          // close member discount
          // this.isMember = profile.isMember || false;
          this.isEarly = payments.fbTime() >= earlyStart
            && payments.fbTime() <= earlyEnd && payments.fbTime() < saleStart;
          if (!this.isEarly && payments.fbTime() < saleStart) {
            this.$store.dispatch('setError', errorMsg['store/not-open']);
            this.$router.push('/store');
          }
          if (payments.fbTime() > saleEnd) {
            this.$store.dispatch('setError', errorMsg['store/closed']);
            this.$router.push('/store');
          }
          if (limit === 0 || (participants && participants.length >= limit)) {
            this.$store.dispatch('setError', errorMsg['store/full']);
            this.$router.push('/store');
          }
          this.productData = productData;
          this.memberLevel = profile.memberLevel || '';
          this.price = prices.sale;
          this.productName = name;
          if (!this.noPromo) {
            if (!this.isMember) {
              if (this.isEarly) {
                this.discount = prices.sale - prices.earlyBird.nonMember;
              }
            } else {
              const lvl = this.memberLevel;
              this.memberStat = await userServices.getMemberDiscount({ lvl });
              if (this.isEarly) {
                this.discount = prices.sale - prices.earlyBird[lvl];
              } else {
                const memDiscount = this.memberStat.discount / 100;
                this.discount = prices.sale * memDiscount;
              }
            }
          }
        } else {
          this.$store.dispatch('setLoading', false);
          this.$store.dispatch('setError', errorMsg['store/not-found']);
          this.$router.push('/store');
        }
        this.$store.dispatch('setLoading', false);
      })
      .catch(() => {
        this.$store.dispatch('setLoading', false);
        this.$store.dispatch('setError', errorMsg['connection/unavailable']);
        this.$router.push('/store');
      });
  },
  methods: {
    total() {
      return numeral(this.price - this.discount).format('0,0');
    },
    getPrice() {
      return numeral(this.price).format('0,0');
    },
    showDiscount() {
      return numeral(this.discount).format('0,0');
    },
    async apply() {
      const { sku } = this.$route.params;
      if (this.promotion) {
        this.$store.dispatch('setLoading', true);
        await payment.applyPromotion(this.promotion.trim().toUpperCase())
          .then(async (res) => {
            if (res) {
              if (res.endDate < payments.fbTime()) {
                this.$store.dispatch('setError', getErrorMsg(this.promotion.trim().toUpperCase(), 'pro/exp'));
                this.discount = 0;
              } else if (res.privilegeProducts.length > 0 && !res.privilegeProducts.includes(sku)) {
                this.$store.dispatch('setError', getErrorMsg(this.promotion.trim().toUpperCase(), 'pro/cannot'));
                this.discount = 0;
              } else {
                if (res.discountType === payment.PROMO_TYPE.STATIC) {
                  this.discount = res.discount;
                } else if (res.discountType === payment.PROMO_TYPE.PERCENTAGE) {
                  this.discount = this.price * (res.discount / 100);
                }
                if (this.price - this.discount < 0) {
                  this.discount = this.price;
                }
              }
            } else {
              this.$store.dispatch('setError', getErrorMsg(this.promotion.trim().toUpperCase(), 'pro/not-found'));
              this.discount = 0;
            }
          })
          .catch(() => {
            this.$store.dispatch('setError', errorMsg['other/fallback']);
          });
        this.$store.dispatch('setLoading', false);
      } else {
        this.discount = 0;
      }
    },
    onSelectedTax(address) {
      this.subdistrict = address.subdistrict;
      this.district = address.district;
      this.province = address.province;
      this.postalCode = address.postalCode;
    },
    onSelectedPost(address) {
      this.subdistrictPost = address.subdistrict;
      this.districtPost = address.district;
      this.provincePost = address.province;
      this.postalCodePost = address.postalCode;
    },
    next() {
      const { uid } = this.$store.getters.user;
      const { sku } = this.$route.params;
      const order = {
        uid,
        price: this.price,
        promotion: this.selectRadio === 'promotionSelected' ? this.promotion : '-',
        isMember: this.isMember,
        discount: this.discount,
        sku,
      };
      if (this.radioTax === 'need') {
        order.tax = {
          name: this.nameth,
          taxid: this.taxid,
          tel: this.tel,
          address: `${this.address} ${this.subdistrict} ${this.district} ${this.province} ${this.postalCode}`,
        };
        if (this.radioPost === 'notSame') {
          order.post = {
            name: this.namethPost,
            tel: this.telPost,
            address: `${this.addressPost} ${this.subdistrictPost} ${this.districtPost} ${this.provincePost} ${this.postalCodePost}`,
          };
        } else {
          order.post = {
            name: this.nameth,
            tel: this.tel,
            address: `${this.address} ${this.subdistrict} ${this.district} ${this.province} ${this.postalCode}`,
          };
        }
      }
      if (this.price < 20) {
        order.status = 'successful';
        this.$router.push({ name: 'complete', params: { sku, orderCheckout: order } });
      } else {
        this.$router.push({ name: 'method', params: { sku, orderCheckout: order } });
      }
    },
  },
  computed: {
    checkNext() {
      return this.needLink ? this.checkbox && this.$store.getters.profile
      && this.$store.getters.profile.cumaEmail : this.checkbox;
    },
  },
  watch: {
    radiosMember(val) {
      if (val === 'memberSelected') {
        if (this.isEarly) {
          const { prices } = this.productData;
          this.discount = prices.sale - prices.earlyBird[this.memberLevel];
        } else {
          const memDiscount = this.memberStat.discount / 100;
          this.discount = this.price * memDiscount;
        }
      } else {
        this.apply();
      }
    },
    radiosEarly(val) {
      if (val === 'earlySelected') {
        const { prices } = this.productData;
        this.discount = prices.sale - prices.earlyBird.nonMember;
      } else {
        this.apply();
      }
    },
    promotion(val) {
      this.promotion = val.trim().toUpperCase();
    },
  },
};
</script>


<style lang="sass" scoped>
.ct-mw
  max-width: 960px
  margin: auto
  margin-top: -36px !important
.white-bg
  max-width: 1100px
  margin: auto
.section
  padding: 16px
  border-color: #DBDBDB
  border-radius: 5px
  border-style: solid
  margin: auto
  @media screen and (max-width: 770px)
    padding: 8px
.caption
  font-size: 1.0rem
  @media screen and (max-width: 340px)
    font-size: 0.9rem
.detail-text
  font-weight: 200
  @media screen and (max-width: 770px)
    font-size: 1.0rem
    line-height: 1.4rem
.bg-grey
  background-color: #DBDBDB
  padding-top: 4px
  padding-bottom: 4px
.border-bottom
  border-bottom-style: solid !important
  border-bottom-width: 0.5px !important
  border-bottom-color: black !important
.v-label
  height: auto !important
</style>
